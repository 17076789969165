import APIService from './APIService';

export default {
  getAllDatabases() {
    return APIService.apiCall().get(
      '/geo-perdas/extracoes-geo-perdas/get-all-databases'
    );
  },

  getDatabaseStructure(id) {
    return APIService.apiCall().get(
      `/geo-perdas/extracoes-geo-perdas/get-database-structure/${id}`
    );
  },

  getQueryData(id, query) {
    return APIService.apiCall().get(
      `/geo-perdas/extracoes-geo-perdas/get-query-data/${id}/${query}`
    );
  },

  getCountRows(id, table) {
    return APIService.apiCall().get(
      `/geo-perdas/extracoes-geo-perdas/get-count-rows/${id}/${table}`
    );
  },

  getAllExtracoes() {
    return APIService.apiCall().get(
      '/geo-perdas/extracoes-geo-perdas/get-all-extracoes/'
    );
  },

  save(postData) {
    return APIService.apiCall().post(
      '/geo-perdas/extracoes-geo-perdas/save',
      JSON.stringify(postData)
    );
  },

  baixarExtracao(id, config) {
    return APIService.apiCall().get(
      `/geo-perdas/extracoes-geo-perdas/download-extracao/${id}`,
      { responseType: 'blob', ...config }
    );
  }
};
