<template>
  <base-material-card
    color="primary"
    icon="mdi-folder-network-outline"
    inline
    class="ml-0"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">GeoPerdas</div>
    </template>

    <div>
      <v-select
        v-model="selectedDatabase"
        name="databases"
        label="Databases"
        :items="databases"
        item-value="id"
        item-text="nome"
        return-object
        class="mt-4"
        dense
        solo
        @change="getDatabaseStructure()"
      />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="mb-5"
        label="Buscar"
        hide-details
        single-line
      />
    </div>

    <v-row
      class="my-4"
      align="center"
      justify="center"
      v-if="loadingTreeView"
    >
      <v-progress-circular
        indeterminate
        width="2"
      />
    </v-row>

    <v-treeview
      v-else
      :items="items"
      :active.sync="active"
      :open.sync="openTabs"
      :search.sync="search"
      activatable
      item-key="id"
      selectedDatabase
      open-on-click
      :multiple-active="false"
      :transition="true"
      class="mt-4"
    >
      <template v-slot:prepend="{ item, open, leaf }">
        <v-icon
          v-if="leaf"
          :key="item.id + tables[item.id]"
        >
          {{ item.id in tables ? 'mdi-table' : '' }}
        </v-icon>
        <v-icon v-else>
          {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
        </v-icon>
      </template>

      <template v-slot:label="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="text-truncate"
            >
              {{ item.name }}
            </div>
          </template>
          <span>{{ item.name }}</span>
        </v-tooltip>
      </template>
    </v-treeview>
  </base-material-card>
</template>

<script>
import ExtracoesGeoPerdasService from '@/services/ExtracoesGeoPerdasService';

export default {
  created() {
    this.getAllDatabases();
  },
  data() {
    return {
      items: [],
      active: [],
      tables: [],
      headers: [],
      openTabs: [],
      databases: [],
      selectedDatabase: [],
      databaseStructure: [],
      search: null,
      loadingTreeView: false
    };
  },
  methods: {
    emitData() {
      this.$emit('update-values', {
        selectedTable: this.selectedTable,
        databaseId: this.databaseId,
        tables: this.tables,
        active: this.active
      });
    },
    getAllDatabases() {
      return ExtracoesGeoPerdasService.getAllDatabases()
        .then(({ data }) => {
          this.databases = data.map((item) => ({
            id: item.id,
            nome: item.nome
          }));

          if (this.databases.length === 1) {
            this.selectedDatabase = {
              id: this.databases[0].id,
              nome: this.databases[0].nome
            };
            this.getDatabaseStructure();
          }
        })
        .catch((err) => {
          this.$toast.error(
            'Erro ao buscar a estrutura do banco de dados.',
            '',
            {
              position: 'topRight'
            }
          );
        });
    },
    getDatabaseStructure() {
      this.loadingTreeView = true;

      return ExtracoesGeoPerdasService.getDatabaseStructure(this.databaseId)
        .then(({ data }) => {
          this.databaseStructure = Object.entries(data);
        })
        .catch((err) => {
          this.$toast.error(
            'Erro ao buscar a estrutura do banco de dados.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => this.mountItemsDirectory());
    },
    mountItemsDirectory() {
      this.items = this.databaseStructure.map(
        ([database, databaseValue], index) => ({
          id: index + 1,
          name: database,
          disabled: false,
          children: Object.entries(databaseValue).map(
            ([schema, tableValue], schemaIndex) => ({
              id: `${index + 1}.${schemaIndex + 1}`,
              name: schema,
              children: Object.entries(tableValue).map(
                ([table, value], tableIndex) => (
                  (this.tables[
                    `${index + 1}.${schemaIndex + 1}.${tableIndex + 1}`
                  ] = {
                    tabela: value,
                    path: `${database}.${schema}`
                  }),
                  {
                    id: `${index + 1}.${schemaIndex + 1}.${tableIndex + 1}`,
                    name: value
                  }
                )
              )
            })
          )
        })
      );
      this.loadingTreeView = false;
    }
  },

  computed: {
    selectedTable() {
      const selected = this.active.length ? this.active[0] : null;
      const item = this.tables[selected] ?? null;

      return item ? item.tabela : null;
    },
    databaseId() {
      return this.selectedDatabase.id ? this.selectedDatabase.id : null;
    }
  },

  watch: {
    selectedTable() {
      this.emitData();
    },
    databaseId() {
      this.emitData();
    },
    active() {
      this.emitData();
    },
    tables: {
      handler() {
        this.emitData();
      },
      deep: true
    }
  }
};
</script>
